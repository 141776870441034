import React from "react"

import { Container, Row, Col } from 'reactstrap'
import Link from '../components/link'
import Button from '../components/btn'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from '../components/form'
import Slider from '../components/slider'
import Box from '../components/box'
import Hr from '../components/hr'
import { FaHome, FaDesktop, FaMobileAlt, FaChartLine, FaBong, FaChalkboardTeacher } from 'react-icons/fa';
import {IoIosArrowDroprightCircle} from 'react-icons/io';
import { GoDashboard } from 'react-icons/go';
import Clients from '../components/clients'
import styled from 'styled-components'
import PageTitle from '../components/page-title'
import Img from 'gatsby-image/withIEPolyfill'
import { FaCode } from 'react-icons/fa'

let StyledImg = styled(props => <Img {...props}/>)`
  perspective: 1500px;
  perspective-origin: left center;
  overflow: visible !important;
  picture, img {
    transform: rotateY(-35deg) rotateX(15deg);
    box-shadow: 25px 60px 125px -25px rgba(80,102,144,.1), 16px 40px 75px -40px rgba(0,0,0,.2);
    border-radius: .625rem;
    transition: 1s !important;
    &:hover {
      transform: rotateY(-30deg) rotateX(15deg);
    }
  }
`

let StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`

const Background = styled.div`
  background: #1bdfc5;
  color: white;
  padding: 5rem 0;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
  text-align: center;
`

let Service = ({title, Icon = IoIosArrowDroprightCircle, content}) => (
  <div className="d-flex mb-4">
    <div><IoIosArrowDroprightCircle size={30} className="text-primary"/></div>
    <div className="ml-3">
      <h4>{title}</h4>
      <p className="m-0 text-muted">{content}</p>
    </div>
  </div>
)

let Tool = ({title, icon, content}) => (
  <div className="d-flex mb-5">
    <img src={icon} class="text-primary" width="75px"/>
    <div className="ml-3">
      <h4>{title}</h4>
      <p className="m-0 text-muted">{content}</p>
    </div>
  </div>
)

export default () => (
  <Layout>
    <SEO title="Website &amp; Mobile Apps Tracking | Empathy | Data agency" description="Empathy help business make better digital presence with data. We specalize in web / app tracking, tag manager implementation, data visaulization and AB testing." keywords="web analytics, app analytics, Google Analytics, Adobe Analytics, Google Tag Manager, Firebase, AppsFlyer, tracking implementation"/>
    <Background>
      <h2>"What gets measured, gets managed."</h2>
      <p>Peter Drucker - the founder of modern management</p>
    </Background>
    <Container className="pt-4">
      <div className="text-center">
        <h1>Website &amp; App Tracking</h1>
        <p className="text-muted">Holistic user behaviour tracking on website &amp; mobile app, from design to implement.</p>
      </div>
    </Container>
    <Container className="pt-4">
      <Row>
        <Col>
          <Service title="Measurement strategy &amp; planning" content="Design what customer behaviour to track that helps you understand them."/>
          <Service title="Tracking implementation" content="Work with your developers &amp; IT to add tracking capability to your website or app."/>
          <Service title="Tracking audit" content="Review your current tracking approach &amp; and recommend improvements."/>
        </Col>
        <Col>
          <Service title="Ecommerce tracking" content="Capture transactional and shopping behaviour data into your digital analytics platform."/>
          <Service title="Marketing pixel installation" content="Implement marketing pixels (e.g. ad serving platform tags) for attributing campaign success."/>
          <Service title="Tag management" content="Design &amp; implement tag management system for you to manage tracking codes easily."/>
        </Col>
      </Row>  
    </Container>   
    <Container className="py-4">
      <h2 className="text-primary mb-2">Platforms We Use</h2>
      <p class="mb-5">We help business to pick suitable analytics platforms and customize them to meet business needs.</p>
      <Row>
          <Col>
            <Tool title="Google Analytics (GA)" icon="/images/logo/ga.svg" content="Most popular web analytics platform. We help brands create account, setup filters &amp; goals."/>  
            <Tool title="Google Tag Manager (GTM)" icon="/images/logo/gtm.png" content="Free and robust tag management solution. We setup and implement any tags via GTM that're required for your needs."/>  
          </Col>
          <Col>
            <Tool title="Firebase Analytics" icon="/images/logo/firebase.png" content="Next generation free mobile apps analytics platform. We configure account &amp; setup conversion funnels."/>  
            <Tool title="AppsFlyer" icon="/images/logo/appsflyer.png" content="Feature-rich paid mobile apps analytics solution with connections to varierty of mobile ad networks."/>  
          </Col>
      </Row>
    </Container>
    <StyledBackground>
      <div className="py-5">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <Box style={{textAlign: 'left'}}>
                <h3 className="text-center">Contact Us</h3>
                <Hr/>
                <Form/>
              </Box>
            </Col>
          </Row>
        </Container>
      </div>
    </StyledBackground>
  </Layout>
)
